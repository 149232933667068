.marked-row {
    background-color: red;
}

.history-table {
    margin: 30px;
}

.MuiTableCell-head {
    text-align: center;
}