.loading {
  border: 12px solid #bdbdbd;
  border-radius: 50%;
  border-top: 12px solid #2196f3;
  width: 120px;
  height: 120px;
  margin: 75px auto 0 auto;
  -webkit-animation: spin 0.75s ease-out infinite; /* Safari */
  animation: spin 0.75s ease-out infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}