body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  padding-bottom: 50px;
}

html, body {
  height: 100%;
}

select {
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
}

h1, h2, h3, h4 {
  text-align: center;
}

input {
  padding: 8px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 14pt;
  margin: 5px;
}

input:active {
  border-color: cornflowerblue;
  /* TODO */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
