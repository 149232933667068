.reload-log-btn, .start-pp-btn, .disabled-start-pp{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #a9a9a9;
    color: #fff;
} 

.reload-log-btn, .start-pp-btn {
    max-width: 200px;
    cursor: pointer;
}

.reload-log-btn {
    background-color: #42a5f5;
    margin-left: auto;
}
.reload-log-btn:hover { background-color: #80d6ff; }
.reload-log-btn:active { background-color: #fff ; color: #80d6ff; }

.start-pp-btn { background-color:#66bb6a; }
.start-pp-btn:hover { background-color: #98ee99; }
.start-pp-btn:active { background-color: #fff; color: #98ee99; }
.disabled-start-pp {
    background-color: #98ee99 !important;
    color: #fff;
    cursor: default !important;
    width: 333px !important;
}
.disabled-start-pp .icon {
    margin-right: 15px;
}
.current-proj-dropdown-button {
    border-radius: 5px;
    background-color: lightgray;
}

.server-status {
    text-align: left;
}

.pp-controller-container {
    margin: 10px 30px 20px;
}

.pp-icon {
    height: 16pt;
    width: 16pt;
    margin-right: 10px;
}

.log-controller {
    display: flex;
    margin: 20px 0 10px;
}

.log-label {
    font-size: 16pt;
    align-self: flex-end;
}

.log-container {
    font-size: 13px;
    white-space: pre;
    font-family: 'Courier New', Arial;
    margin: 20px 0;
    padding: 10px 10px 50px;
    background-color: #e6e6e6;
    border: 1px solid darkgray;
    border-radius: 6px;
}