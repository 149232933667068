.dropdown-button {
    display: inline-block;
    padding: 10px 5px 10px 15px;
    margin: 20px 0;
    border-radius: 5px;
    background-color: #fff;
    border: 3px solid #3cb8f0;
}

.dropdown-button:hover {
    background-color: #ebfaff;
}
.dropdown-button:active {
    color: #3cb8f0;
    background-color: #fff;
    border-color: #ebfaff;
}

.dropdown-button .icon {
    height: 16px;
    width: auto;
    margin-left: 35px;
    margin-right: 5px;
}

.dropdown-label {
    margin-left: 10px;
}