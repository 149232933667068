.match-container {
    text-align: center;
}

.match-descr {
    font-size: 15pt;
    display: flex;
    justify-content: center;
    align-items: center;
}

.match-text {
    font-size: 18pt;
    margin: 50px 0;
}

.match-info {
    font-size: 13pt;
}

.quality-container {
    text-align: center;
    margin: 40px 0 20px 0;
}

.quality-control-container {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
}

.quality-control {
    min-width: 75px;
    display: flex;
    font-size: 18pt;
    align-items: center;
    padding: 10px;
    border: 2px solid #bdbdbd;
    justify-content: center;
    border-radius: 5px;
    background: rgb(29, 132, 216);
    color: #fff;
    margin: 5px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
}

.quality-control .icon, .btn-new .icon {
    height: 20pt;
    width: 20pt;
    margin-right: 10px;
}

.yes-btn { background-color: #66bb6a; }
.yes-btn:hover { background-color: #98ee99; }
.yes-btn:active { background-color: #fff; color: #98ee99; }

.no-btn { 
    background-color: #ef5350; 
    border-color: #ff867c; 
}
.no-btn:hover { background-color: #ff867c; }
.no-btn:active { background-color: #fff; color: #ef5350; }

.ignore-btn { background-color: #42a5f5; }
.ignore-btn:hover { background-color: #80d6ff; }
.ignore-btn:active { background-color: #fff; color: #42a5f5; }

.skip-btn {
    background-color: #78909c;
}
.skip-btn:hover { background-color: #a7c0cd; }
.skip-btn:active { background-color: #fff; color: #a7c0cd }

.no-match-found-container {
    margin-top: 75px;
    text-align: center;
}

.no-match-found-container p {
    font-size: 18pt;
}

.all-picker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.break-row {
    flex-basis: 100%;
    height: 0;
}

@media only screen and (max-width: 1500px) {
    .break-row {
      flex-basis: 0%;
    }
}