.quality-picker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 60px 20px 0;
}

.quality-control-and-descr-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.quality-picker-container .quality-control {
    zoom: .9;
}

.quality-picker-container .quality-control.disabled {
    cursor: default !important;
    opacity: 0.7 !important;
}

.match-descr {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;
}

.is-it-match-label {
    margin: 3px 0 10px;
}

.overlay-quality-picker {
    position: absolute;
    display: none;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -10px;
    left: -10px;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    transition: display 0.5s;
    border-radius: 6px;
    color: #fff;
}

.not-displayed {
    display: none !important;
}