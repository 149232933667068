.navbar-container {
    width: 100%;
    height: 75px;
    background-color: #2e7d32;
    padding-left: 0 100px;
    display: flex;
}

.navbar-control {
    font-size: 16pt;
    cursor: pointer;
    user-select: none;
    background-color: inherit;
    color: #fff;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    padding: 0px 25px;
    height: 100%;
    text-align: center;
}

.active-nav-tab {
    background-color: #60ad5e;
}

.navbar-control:last-child {
    margin-left: auto;
}

.navbar-control:hover {
    background-color: #60ad5e;
}

.navbar-container .icon {
    padding-right: 10px;
    height: 16pt;
    width: 16pt;
    color: #fff;
}