.import-files-container {
    align-items: center;
}

.import-files-container form {
    display: flex;
    align-items: center;
}

.upload-btn { 
    background-color: #9575cd; 
    border: none;
    color: #fff; 
    display: inline-block;
    padding: 9px 15px;
    font-size: 16pt;
    border-radius: 5px;
}
.upload-btn:hover { background-color: #c7a4ff; }
.upload-btn:active { background-color: #fff; color: #9575cd; border: none; outline: none;}

.upload-btn .icon {
    height: 16pt;
    width: auto;
    margin-right: 15px;
}

.upload-count-container {
    display: flex;
    justify-content: center;
}