/* .btn {
    cursor: pointer;
} */

.delete-btn .icon {
    height: 16pt;
    width: 16pt;
    padding: 7px;
}

.delete-btn {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    justify-content: center;
    color: #f44336;
    background-color: rgb(255, 255, 255, 0);
    transition: background-color .1s linear,color .1s linear;
}

.delete-btn:hover {
    color: #fff;
    background-color: #f44336;
}

.btn:active {
    color: lightgray;
}

.marked-word {
    background-color: #ffeb3b;
    padding: 0;
    border-radius: 7px;
}

.color-0 { background-color: #ffeb3b; }
.color-1 { background-color: #ff867c; }
.color-2 { background-color: #df78ef; }
.color-3 { background-color: #80d6ff; }
.color-4 { background-color: #98ee99; }

.sndCol0 {background: linear-gradient(45deg, #ffeb3b 50%, rgba(0, 0, 0, 0) 50%);}
.sndCol1 {background: linear-gradient(45deg, #ff867c 50%, rgba(0, 0, 0, 0) 50%);}
.sndCol2 {background: linear-gradient(45deg, #df78ef 50%, rgba(0, 0, 0, 0) 50%);}
.sndCol3 {background: linear-gradient(45deg, #80d6ff 50%, rgba(0, 0, 0, 0) 50%);}
.sndCol4 {background: linear-gradient(45deg, #98ee99 50%, rgba(0, 0, 0, 0) 50%);}

.noColor { background: transparent; background-color: transparent; } 