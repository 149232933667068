.btn-basic {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18pt;
    padding: 10px 15px;
    margin: 10px;
    border-radius: 5px;

    border: none;
    outline: none;
}

.disabled {
    cursor: default !important;
    opacity: 0.6;
}

.btn-basic .icon {
    height: 18pt;
    width: auto;
}

.btn-basic span {
    margin-left: 15px;
}


.edit-user-btn { color: #fff; background-color: #2196f3; }
.edit-user-btn:hover { background-color: #6ec6ff; }
.edit-user-btn:active { background-color: #fff; color: #2196f3; }
.edit-user-btn.disabled { background-color: #6ec6ff; }

.blue-btn { 
    color: #fff; 
    background-color: #42a5f5; 
}
.blue-btn:active { 
    background-color: transparent; 
    color: #42a5f5;
    box-shadow: none !important; 
    -webkit-box-shadow: none !important;
}
.blue-btn:hover { 
    -webkit-box-shadow: 1px 3px 10px 2px #42a5f5; 
    box-shadow: 1px 3px 10px 2px #42a5f5; 
 }

.red-btn { 
    color: #fff; 
    background-color: #ef5350; 
}
.red-btn:active { 
    background-color: transparent; 
    color: #ef5350; 
    box-shadow: none !important; 
    -webkit-box-shadow: none !important;
}
.red-btn:hover { 
    -webkit-box-shadow: 1px 3px 10px 2px #ef5350; 
    box-shadow: 1px 3px 10px 2px #ef5350; 
 }

.green-btn { 
    color: #fff; 
    background-color: #66bb6a; 
}
.green-btn:active { 
    background-color: transparent; 
    color: #66bb6a; 
    box-shadow: none !important; 
    -webkit-box-shadow: none !important;
}
.green-btn:hover { 
    -webkit-box-shadow: 1px 3px 10px 2px #66bb6a; 
    box-shadow: 1px 3px 10px 2px #66bb6a; 
 }

.disabled { 
    color: #fff !important; 
    background-color: #9e9e9e !important; 
    cursor: default !important; 
    box-shadow: none !important; 
    -webkit-box-shadow: none !important;
}

.btn-basic-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}