#table {
    min-width: 400px;
    margin: auto;
    font-size: 16pt;
    text-align: center;
    border-collapse: collapse;
    transition: background-color 0.25s;
}

#table thead th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #29b6f6;
    font-weight: 600;
    color: white;
}

#table tr:nth-child(even){background-color: #f2f2f2;}

#table tr:hover {background-color: #ddd;}

#table td, #table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.margin-table {
    margin: 30px auto !important;
}