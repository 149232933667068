.word-count-slider {
    display: inline-block;
    max-width: 300px;
}

.filter-select {
    margin-top: 10px;
}

.select__input {
    margin-left: -4px /* so the cursor is in line with the place holder*/
}

.match-text {
    width: 80%;
    max-width: 1700px;
    margin: 50px auto !important;
}

.all-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}

.btn-new {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 25px 15px;
}  

.btn-new .icon {
    height: 20px;
    width: auto;
    margin-right: 15px;
}

.apply-filter-button { 
    background-color: #42a5f5; 
    border-color: #80d6ff; 
    color: #fff;
    
}
.apply-filter-button:hover { background-color: #80d6ff; }
.apply-filter-button:active { background-color: #fff; color: #42a5f5; border-color: #42a5f5;}
.btn-disabled {
    cursor: default !important;
    color: #fff;
    background-color: #80d6ff !important;
}

.flex-break-row {
    flex-basis: 100%;
    height: 0;
}

.flex-break-coll {
    flex-basis: 100%;
    width: 0;
}

.filter-container {
    width: 300px;
    display: inline-block;
    margin: 0 10px;
    text-align: center;
}

.activate-filter-container {
    flex-basis: 100%;
    padding: 0 25px;
    margin: 30px 10px;
}

.filter-label {
    text-decoration: underline;
}

.rc-slider {
    margin: 25px 5px 15px;
    width: calc(100% - 10px);
}