.admin-nav-container {
    width: 100%;
    background-color: #5EAD62;
    display: block;
}

.admin-nav-item {
    display: inline-block;
    padding: 7px 12px;
    background-color: #5EAD62;
    color: #fff;
    cursor: pointer;
    transition: 0.25s linear background-color;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.admin-content-container {
    width: 100%;
    margin-top: 50px;
}

.material-table-container {
    display: flex;
    justify-content: center;
}

.active {
    background-color: #8fdf90;
}

.admin-nav-item:hover {
    background-color: #8fdf90;
}