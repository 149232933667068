.tooltip-icon {
    height: 16px;
    color: #1e88e5;
    width: 16px;
    margin-left: 5px;
}

.edit-icon {
    /* todo hover effect */
    height: 24px;
    color: #1e88e5;
    width: 24px;
    cursor: pointer;
}

.td-no-pad {
    padding: 0 8px !important;
}

.editable-span {
    display: block;
    height: 100%;
    width: 100%;
    padding: 8px 0;
}

.edit-mode {
    border: 2px solid #000;
}

.check-icon {
    /* todo hover effect */
    height: 24px;
    color: #43a047;
    width: 24px;
    cursor: pointer;
}

.tool-tip {
    max-width: 350px !important;
    font-size: 14px !important;
}

.preproc-settings-container {
    margin-bottom: 20px;
}

.focus-border {
    border: 2px solid #000;
}

.clickable-div {
    cursor: pointer;
}

.ReactModal__Overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    background-color: rgba(0, 0, 0, .8) !important;
}

.ReactModal__Content {
    display: flex;
    flex-flow: column;
    max-width: 500px;
    max-height: 350px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 20px;
    position: absolute;
    border-radius: 5px;
    border: 2px solid #ddd;
    background-color: #fff;
    min-height: 400px;
}

.current-proj-dropdown-button {
    border-radius: 5px;
    background-color: lightgray;
}

.ReactModal__Content form input {
    margin: 10px 0;
    width: calc(100% - 20px);
}

.ReactModal__Content form textarea {
    width: calc(100% - 8px);
}

.exit-btn {
    font-size: 18px;
    margin: 10px;
    padding: 5px 10px;
    color: #fff;
    background-color: #ef5350;
    border: 0px solid #000;
    border-radius: 4px;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.exit-btn:hover {
    background-color: #ff867c;
}

.exit-btn:active {
    color: #ef5350;
    background-color: transparent;
}

.add-btn {
    border-radius: 6px;
    cursor: pointer;
    background-color: #65ba69;
    color: #fff;
    padding: 15px 20px;
    margin: 5px 0;
    display: inline-block;
}

.add-btn-container {
    display: flex;
    justify-content: center;
}

.modal-controls .add-btn {
    position: absolute;
    right: 20;
    bottom: 20;
}

.add-btn:hover { background-color: #97ed98; }
.add-btn:active { background-color: #fff; color: #65ba69;}

.add-btn .icon {
    height: 16pt;
    width: auto;
    margin-right: 15px;
}

.add-form-title {
    text-align: center;
}