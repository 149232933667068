.edit-user-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.edit-user-form .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.edit-user-form .input-container label {
    flex-grow: 1;
    min-width: 125px;
    display: flex;
    align-items: center;
}

.edit-user-form .input-container input {
    flex-grow: 50;
    flex-basis: auto;
}

.edit-user-form .input-container input[type="checkbox"] {
    width: 35px;
}

.edit-user-form .input-container .switch {
    margin: 10px 0;
}


.user-modal-overlay .ReactModal__Content {
    max-height: 600px;
}