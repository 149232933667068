.login-form {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 25px;
    align-self: center;
    margin: 0 auto;
}

.invalid-login-container {
    text-align: center;
    font-size: 16pt;
    font-weight: bold;
    color: red;
    margin: 45px 0 0 0;
}